import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'


const Events = [
    {
        title: 'Damas',
        li1: 'Recomendamos vestidos ligeros, zapatos bajos y cómodos.',
        li2: 'No tacones de punta',
        animation: '1200',
    },
    {
        title: 'Caballeros',
        li1: 'Recomendamos no utilizar saco, puedes usar pantalones de lino, algon y colores claros para mas comodidad.',
        animation: '1000',
    }
]

const DresscodeSection = (props) => {
    return (
        <section className="wpo-event-section-s4" id="event">
            <div className="container">
                <SectionTitle MainTitle={'Vestimenta'} />
                <div className="wpo-event-wrap">
                    <p className={'text-center m-5'}>Queremos que todos se sientan libres y cómodos. Por favor, vistan de manera informal pero elegante para disfrutar de este día.</p>
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-6 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <p className={'p-5'}>
                                                {event.li1} <br/>
                                                {event.li2}
                                            </p>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DresscodeSection;