import axios from 'axios';

// Define the base URL for easier endpoint management
const API_BASE_URL = 'https://htv1lxpwxg.execute-api.us-east-1.amazonaws.com/prod';

// Function to get invitation details by phone number
export const fetchInvitationByPhoneNumber = async (phoneNumber) => {
	try {
		const response = await axios.get(`${API_BASE_URL}/check-invite?phone_number=${phoneNumber}`,{
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return response.data;  // Assuming the response has the data directly
	} catch (error) {
		console.error("Error fetching invitation details: ", error);
		throw error;  // Re-throwing the error to handle it in the component
	}
};



export const confirmInviteByMainGuestId = async (guestInfo) => {
	try {
		const response = await axios.put(`${API_BASE_URL}/confirm-invite`,{
			"main_guest_id": guestInfo.main_guest_id,
			"main_guest_is_coming": guestInfo.main_guest_is_coming,
			"party_members_assisting": guestInfo.party_members_assisting,
			"is_staying_at_hotel": guestInfo.is_staying_at_hotel
		},{
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching invitation details: ", error);
		throw error;  // Re-throwing the error to handle it in the component
	}
};