import React from 'react'
import sImg1 from '../../images/story-1.jpg'
import sImg2 from '../../images/story-2.jpg'
import sImg3 from '../../images/story-3.jpg'
import SectionTitle from '../SectionTitle'


const StorySection = (props) => {
    return (

        <section className="wpo-story-section-s4 section-padding" id="story">
            <div className="container">
                <SectionTitle subTitle={'Nuestra Historia'} MainTitle={'¿Cómo ocurrió todo?'} />
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="round-shape"></div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder right-align-text left-site">
                                        <img src={sImg1} alt="" className="img img-responsive" />
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date">14 Sept, 2020</span>
                                        <h3>¡Nos conocimos en Pandemia!</h3>
                                        <p>Nos conocimos el 14 de septiembre de 2020, en medio de un mundo cambiante. Lo que comenzó como charlas en tiempos inciertos, floreció rápidamente, y para el 6 de noviembre, sabíamos que queríamos caminar juntos hacia el futuro. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                        <span className="date">03 Jun, 2022</span>
                                        <h3>Nos comprometimos</h3>
                                        <p>Un 3 de junio, en un escenario donde el horizonte besa el mar y el cielo nos regaló un arcoíris, nuestra familia fue testigo del momento en que decidimos unir nuestros caminos. En aquel acantilado, con el eco del océano como música de fondo, nuestro compromiso se selló, marcando el inicio de una nueva etapa juntos.</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                    <div className="img-holder right-align-img">
                                        <img src={sImg2} alt="" className="img img-responsive" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder video-holder left-site">
                                        <img src={sImg3} alt="" className="img img-responsive" />
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date"> 2 Ago, 2022</span>
                                        <h3>Formamos nuestro hogar!</h3>
                                        <p>En agosto de 2022, decidimos empezar a vivir juntos, marcando un momento importante en nuestra relación. Con la compañía de nuestros dos cachorros Django y Phoebe, comenzamos a crear nuestro propio espacio, llenándolo con nuestras cosas, nuestras ideas, y por supuesto, mucha energía perruna. Este paso significó mucho más que compartir un techo; fue el inicio real de nuestro hogar, un lugar hecho de nuestras experiencias compartidas.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <div className="round-shape-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection;