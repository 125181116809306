

import React, {Fragment} from 'react';


import { Button, Dialog, Grid, } from '@mui/material'
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import waze_icon from '../../../images/icons-waze.png'

const LocationMap = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }


    const DialogTitle = ((props) => {
        const { onClose } = props;
        return (
          <MuiDialogTitle>
            {onClose ? (
              <IconButton className='event-close-btn' aria-label="close" onClick={onClose}>
                <i className="ti-close"></i>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });


    return (
        <Fragment>
            <Button
                className={`btn ${buttonClass}`}
                onClick={handleClickOpen}>
                {button}
                Ver Ubicación
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}> </DialogTitle>
                <Grid className="modalBody modal-body">
                    <Grid className="modalBody modal-body">
                      <p>
                        <iframe
                          title={"Ubicación de la boda"}
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62945.24855619661!2d-84.66361645204091!3d9.588542089669454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f0ac425e043f72b%3A0x5fdf3871d007e017!2sLos%20Sue%C3%B1os%20Marriott%20Ocean%20%26%20Golf%20Resort!5e0!3m2!1ses-419!2scr!4v1714784106798!5m2!1ses-419!2scr"
                          style={{border: 0}} allowFullScreen="" loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"></iframe>
                      </p>
                      <div style={{borderBottom: '1px solid #e0e0e0', margin: '20px 0'}}></div>
                      <p className={"text-center"}>
                        <a href="https://waze.com/ul/hd1eyct2sk">
                          <img src={waze_icon} alt="Waze" className="waze-icon" width={'48px'} height={'48px'} />
                          <br/>
                          https://waze.com/ul/hd1eyct2sk
                        </a>
                      </p>
                    </Grid>
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default LocationMap