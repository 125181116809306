import React from "react";
import background from '../../images/main-slider.jpg'
import TimeCountDown from "../countdown";
import {Slide} from "react-awesome-reveal";

const Hero = () => {
    return (

        <section className="static-hero-s3"
                 style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${background})` }}>
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-12">
                                <div className="wpo-static-hero-inner">
                                    <div className="slide-title">
                                        <h1>¡Nos vamos a casar!</h1>
                                        <h2>Andres <span>&</span> Kala</h2>
                                        <h3>22 de Junio del 2024</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-12">
                                <div className="wpo-wedding-date">
                                    <Slide direction="up" duration="1600" triggerOnce="true">
                                        <div className="clock-grids">
                                            <div id="clock">
                                                <TimeCountDown />
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;