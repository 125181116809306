import React from 'react';

const Footer = (props) => {
    return (
        <footer className={`wpo-site-footer`}>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> &copy; Copyright 2024. Made with ♡ | All right reserved
                                reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;