import React from 'react'

const RSVPConfirmed = () => {

    return (
        <form className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-12 col-12 text-center">
                    <p>Tu asistencia ha sido confirmada.</p>
                  <p>¡Te esperamos!</p>
                </div>
            </div>
        </form >
    )
}

export default RSVPConfirmed;