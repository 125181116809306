import React, { Fragment } from 'react';
import CoupleSection from '../../components/CoupleSection/CoupleSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Hero from "../../components/hero/hero";
import StorySection from "../../components/StorySection/StorySection";
import RsvpSection from "../../components/RsvpSection/RsvpSection";
import EventSection from "../../components/EventSection/EventSection";
import DresscodeSection from "../../components/DresscodeSection/DresscodeSection";
import GiftsSection from "../../components/GiftsSection/GiftsSection";
import PhotosSection from "../../components/PhotosSection/PhotosSection";



const HomePage = () => {

  return (
    <Fragment>
      <Hero/>
      <CoupleSection/>
      <StorySection/>
      <EventSection/>
      <DresscodeSection/>
      <GiftsSection/>
      <RsvpSection/>
      <PhotosSection/>
      <Footer/>
      <Scrollbar/>
    </Fragment>
  );
};

export default HomePage;