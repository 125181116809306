import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  confirmInviteByMainGuestId,
  fetchInvitationByPhoneNumber
} from '../../services/invitationService';

// Asynchronous thunk action
export const getInvitation = createAsyncThunk(
  'invitation/getInvitation',
  async (phoneNumber, thunkAPI) => {
    try {
      return await fetchInvitationByPhoneNumber(phoneNumber);
    } catch (error) {
      let data = error.response.data;
      return thunkAPI.rejectWithValue(data.message);
    }
  }
);


export const confirmInvitation = createAsyncThunk(
  'invitation/confirmInvitation',
  async (guestInfo, thunkAPI) => {
    try {
      return await confirmInviteByMainGuestId(guestInfo);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const invitationSlice = createSlice({
  name: 'invitation',
  initialState: {
    guestInfo: null,
    error: null,
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'
  },
  reducers: {
    resetGuestInfo: (state) => {
      state.guestInfo = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvitation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getInvitation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.guestInfo = action.payload;
        state.error = null;
      })
      .addCase(getInvitation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.guestInfo = null;
      });
  }
});


export const { resetGuestInfo } = invitationSlice.actions;
export default invitationSlice.reducer;