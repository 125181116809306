import React from 'react'
import RSVPForm from '../RSVPForm/RSVPForm'
import {useDispatch, useSelector} from "react-redux";
import RSVPConfirmationForm from "../RSVPConfirmationForm/RSVPConfirmationForm";
import RSVPConfirmed from "../RSVPConfirmed/RSVPConfirmed";
import {resetGuestInfo} from "../../store/reducers/invitation";


const RsvpSection = (props) => {
    const dispatch = useDispatch();

    const guestInfo = useSelector(state => state.invitation.guestInfo);
    const handleReset = () => {
        dispatch(resetGuestInfo());
    };

    return (
        <section className={`wpo-contact-section ${props.rClass} section-padding`}>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="wpo-contact-section-wrapper">
                            <div className="wpo-section-title">
                            <h2>{!guestInfo ? 'Asistiras a nuestra boda?' : `Hola ${guestInfo.main_guest.full_name}!` }</h2>
                            </div>
                            <div className="wpo-contact-form-area">
                                {!guestInfo ?
                                  (
                                    <RSVPForm />
                                  ) : (
                                    <>
                                        {guestInfo.main_guest.is_assisting === true ? (
                                          <>
                                            <RSVPConfirmed />
                                            <button style={{float: "right"}} onClick={handleReset} className={'btn btn-xs btn-default'}><p className={'text-danger fw-light'} style={{fontSize: '12px'}}>x (Salir)</p></button>
                                          </>
                                        ) : (
                                            <RSVPConfirmationForm handleReset={handleReset} />
                                        )
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RsvpSection;