import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { confirmInvitation, getInvitation } from '../../store/reducers/invitation';
import { resetGuestInfo } from '../../store/reducers/invitation';

const RSVPConfirmationForm = (props) => {

    const [loading, setLoading] = useState(false);

    const [guestInfo, setGuestInfo] = useState({
        main_guest_is_coming: '',
        party_members_assisting: [],
        is_staying_at_hotel: '',
    });

    const dispatch = useDispatch();
    const guestDetails = useSelector(state => state.invitation.guestInfo);
    const status = useSelector(state => state.invitation.status);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        const phoneNumber = guestDetails.main_guest.phone_number;
        guestInfo.main_guest_id = guestDetails.main_guest._id;

        try {
            // Wait for the confirmation action to complete
            await dispatch(confirmInvitation(guestInfo));

            // Reset guest info after confirmation
            dispatch(resetGuestInfo());

            if (guestInfo.main_guest_is_coming === 'true') {
                // Fetch invitation only if the guest is coming
                await dispatch(getInvitation(phoneNumber));
            }
        } catch (error) {
            console.error('Error during confirmation or invitation fetch:', error);
        } finally {
            setLoading(false);
        }
    };

    // const submitHandler = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //
    //     const phoneNumber = guestDetails.main_guest.phone_number;
    //     guestInfo.main_guest_id = guestDetails.main_guest._id;
    //
    //     try {
    //         // Wait for the confirmation action to complete
    //         await dispatch(confirmInvitation(guestInfo));
    //
    //         // Proceed with resetting guest info and fetching invitation if applicable
    //         dispatch(resetGuestInfo());
    //
    //         if (guestInfo.main_guest_is_coming === 'true') {
    //             dispatch(getInvitation(phoneNumber));
    //         }
    //     } catch (error) {
    //         console.error('Error during confirmation or invitation fetch:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    //
    //     dispatch(confirmInvitation(guestInfo));
    //     dispatch(resetGuestInfo());
    //     setLoading(false);
    //     if (guestInfo.main_guest_is_coming === 'true') {
    //         dispatch(getInvitation(phoneNumber));
    //     }
    // };

    const handleGuestChange = e => {
        const { name, value } = e.target;

        if (name === 'main_guest_is_coming' && value === 'false') {
            setGuestInfo(prev => ({[name]: value, party_members_assisting: [], is_staying_at_hotel: '' }));
        }else{
            setGuestInfo(prev => ({ ...prev, [name]: value }));
        }
    };

    const handlePartyMemberChange = memberId => {
        const updatedMembers = guestInfo.party_members_assisting.includes(memberId)
          ? guestInfo.party_members_assisting.filter(id => id !== memberId)
          : [...guestInfo.party_members_assisting, memberId];

        setGuestInfo(prev => ({ ...prev, party_members_assisting: updatedMembers }));
    };

    return (
      <form onSubmit={submitHandler} className="contact-validation-active">
          <div className="row">
              <div className="col col-lg-12 col-12">
                  <div className="form-field">
                      <label>¿Nos acompañas o te lo pierdes?</label>
                      <select
                        value={guestInfo.main_guest_is_coming}
                        name="main_guest_is_coming"
                        onChange={handleGuestChange}
                        className="form-control">
                          <option value="">Selecciona una opcion</option>
                          <option value="true">Sí, los acompañamos</option>
                          <option value="false">No</option>
                      </select>

                      {guestInfo.main_guest_is_coming === 'true' && (
                        <>
                            {guestDetails.main_guest.party_members && guestDetails.main_guest.party_members.length > 0 && (
                              <div className={'mt-4'}>
                                <label>¿Con {guestDetails.main_guest.party_members.length > 1 ? ('quienes') : ('quién')} vienes?</label>
                                {guestDetails.main_guest.party_members.map(member => (
                                  <div key={member._id}>
                                      <input
                                        type="checkbox"
                                        checked={guestInfo.party_members_assisting.includes(member._id)}
                                        onChange={() => handlePartyMemberChange(member._id)}
                                      /> {member.full_name}
                                  </div>
                                ))}
                              </div>
                            )}
                            <div className="form-field mt-4" >
                                <label>¿Y te quedas con nosotros en el hotel?</label>
                                <select
                                  value={guestInfo.is_staying_at_hotel}
                                  name="is_staying_at_hotel"
                                  onChange={handleGuestChange}
                                  className="form-control">
                                    <option value="">Selecciona una opcion</option>
                                    <option value="true">Sí</option>
                                    <option value="false">No</option>
                                </select>
                            </div>

                            {guestInfo.is_staying_at_hotel === 'true' && (
                              <>
                                  <p>
                                      Perfecto, nos alegra mucho que puedan acompañarnos y quedarse con nosotros en el hotel.
                                  </p>
                                  <p>El hotel pone a disposición de los invitados una tarifa especial.</p>
                                  
                                  <p>
                                      Para hacer la reserva, puedes hacerla mediante el siguiente enlace:
                                      <br/>
                                      <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1713293659928&key=GRP&app=resvlink"
                                      target={"_blank"} rel="noreferrer">
                                          https://www.marriott.com/event-reservations/reservation-link.mi?id=1713293659928&key=GRP&app=resvlink
                                      </a>
                                  </p>
                              </>
                            )}
                        </>
                      )}

                      {guestInfo.main_guest_is_coming === 'false' && (
                        <div>
                            <label>Lamentamos mucho que no puedas asistir a nuestra boda. =(</label>
                        </div>
                      )}
                  </div>
              </div>
          </div>
          <div className="submit-area">
              <div className="btn-group" role="group" aria-label="Basic example">

              <button type="submit" className="theme-btn">
                  {loading || status === 'loading' ? 'Confirmando...' : 'Confirmar mi asistencia!'}
              </button>

              <button type="button" className="btn btn-danger" onClick={props.handleReset}>
                  Salir
              </button>
              </div>
          </div>
      </form>
    );
}

export default RSVPConfirmationForm;
