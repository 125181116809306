import React from 'react'
import { Slide } from "react-awesome-reveal";

import camera_image from '../../images/camera.svg'

const Events = [
    {
        title: 'Wedshoots es la app que usaremos en nuestra boda para compartir fotos y vídeos.',
        li1: 'Puedes descargar la aplicación en el enlace:',
        li2: 'https://www.wedshoots.com?albumId=ES052d7a32',
        animation: '1200',
    },
]

const PhotosSection = (props) => {
  return (
    <section className="wpo-event-section-s4" id="event">
      <div className="container">
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-12" key={eitem}>
                <Slide direction="up" duration={event.animation} triggerOnce="true">
                  <div className="wpo-event-item">
                    <div className="wpo-event-text">
                      <div className="title">
                        <h2 style={{
                          fontFamily: '"Jost", sans-serif',
                          fontSize: "18px",
                        }}>{event.title}</h2>
                      </div>
                      <img src={camera_image} style={{width: "40%"}} alt=""/>
                      <p className={'p-1 mt-3'}>
                        {event.li1} <br/>
                        <a href={event.li2} target={'_blank'} rel={'noreferrer'}>{event.li2}</a>
                      </p>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PhotosSection;
