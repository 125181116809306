import React, { useState } from 'react'
import { getInvitation } from '../../store/reducers/invitation';
import {useDispatch, useSelector} from "react-redux";


const RSVPForm = () => {

    const [phoneNumber, setPhoneNumber] = useState('');
    const dispatch = useDispatch();
    const status = useSelector(state => state.invitation.status);
    const error = useSelector(state => state.invitation.error);

    const submitHandler = e => {
        e.preventDefault();
        dispatch(getInvitation(phoneNumber));
    };

    const isValidPhoneNumber = 7 < phoneNumber.length < 11 && /^\d+$/.test(phoneNumber);

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <label>Utiliza tu número de teléfono para confirmar tu invitación.</label>
                        <input
                            value={phoneNumber}
                            type="number"
                            name="phone_number"
                            onBlur={(e) => setPhoneNumber(e.target.value)}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="form-control"
                            placeholder="Ej: 88008800 or 1239990348" />
                            {error && <p style={{fontSize: '12px', color: 'red'}}>{error}</p>}
                    </div>
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn disabled"
                        disabled={!isValidPhoneNumber}>{status === 'loading' ? 'Cargando...' : 'Ver invitación'}</button>
            </div>
        </form >
    )
}

export default RSVPForm;