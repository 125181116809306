import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle';


const Events = [
    {
        title: 'Colones',
        li1: 'Cliente: ANDRES MONTERO DURAN \n' +
          'Número de cuenta BAC: 933511081 \n' +
          'Número de cuenta IBAN: CR03010200009335110815',
        animation: '1200',
    },
    {
        title: 'Dólares',
        li1: 'Cliente: ANDRES MONTERO DURAN \n' +
          'Número de cuenta BAC: 945764637 \n' +
          'Número de cuenta IBAN: CR83010200009457646371',
        animation: '1200',
    },
];


const GiftsSection = (props) => {
    return (
      <section className="wpo-event-section-s4" id="event">
          <div className="container">
              <SectionTitle MainTitle={'Muestras de Cariño'}/>
              <div className="wpo-event-wrap">
                  <p className={'text-center m-5'}>Cualquier gesto de amor, grande o pequeño, será recibido con enorme
                      gratitud y nos ayudará a construir los cimientos de nuestra vida juntos.</p>
                  <div className="row">
                      {Events.map((event, eitem) => (
                        <div className="col col-lg-6 col-md-6 col-12" key={eitem}>
                            <Slide direction="up" duration={event.animation} triggerOnce="true">
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>{event.title}</h2>
                                        </div>
                                        <p className={'p-5'}>
                                            {event.li1} <br/>
                                            {event.li2}
                                        </p>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                      ))}
                  </div>
              </div>
          </div>
      </section>
    );
};


export default GiftsSection;
